import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button as Btn } from "@material-ui/core";

const ContainedButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: "#06f97f",
    "&:hover": {
      backgroundColor: "#02a151",
    },
    "&:disabled": {
      backgroundColor: "#F3F3F3",
    },
  },
}))(Btn);

const OutlinedButton = withStyles((theme) => ({
  root: {
    color: "#06f97f",
    borderColor: "#06f97f",
    backgroundColor: "white",
    "&:hover": {
      borderColor: "#02a151",
      color: "#02a151",
      backgroundColor: "#FFFFFF",
    },
  },
}))(Btn);

const Button = (props) => {
  if (props.contained) {
    return (
      <ContainedButton
        fullWidth
        itemID={props.id}
        disabled={props.disabled}
        type={props.type}
        onClick={props.type === "submit" ? null : props.onClick}
        variant="contained"
        color="primary"
        href={props.href}
        download={props.download}
        disableElevation
      >
        {props.children}
      </ContainedButton>
    );
  } else if (props.outlined) {
    return (
      <OutlinedButton
        fullWidth
        itemID={props.id}
        disabled={props.disabled}
        type={props.type}
        onClick={props.type === "submit" ? null : props.onClick}
        variant="outlined"
        color="primary"
        href={props.href}
        download={props.download}
        disableElevation
      >
        {props.children}
      </OutlinedButton>
    );
  }
};

export default Button;
