import React, { useContext } from 'react';

import { AuthContext } from '../../context/auth-context'

import Button from '../FormElements/Button';
import LogoutIcon from '../../../assets/Icons/logout.svg';

import './Footer.css';
import { Typography } from '@material-ui/core';

const Footer = () => {
    const auth = useContext(AuthContext);
    return (
        <footer className='footer'>
            <div className='footer__text-container'>
                <Typography>® Metabase Q 2022</Typography>
            </div>
            <div className='footer__button-container'>
                <Button contained onClick={auth.logout}>
                    <div className='footer__button-items'>
                        <div className='footer__button-icon'>
                            <img src={LogoutIcon} alt="" />
                        </div>
                        <p>SALIR</p>
                    </div>
                </Button>
            </div>
        </footer>
    );
}

export default Footer;