import React, { useReducer, useEffect } from 'react';

import { validate } from '../../utils/validators';
import './Input.css';

const inputReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				value: action.val,
				isValid: validate(action.val, action.validators),
			};
		case 'TOUCH':
			return {
				...state,
				isTouched: true,
			};
		case 'RESET':
			return {
				value: '',
				isValid: false,
				isTouched: false,
			};
		default:
			return state;
	}
};

const Input = (props) => {
	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.initialValue || '',
		isTouched: false,
		isValid: props.initialValue || false,
	});

	const { id, onInput } = props;
	const { value, isValid } = inputState;

	useEffect(() => {
		onInput(id, value, isValid);
	}, [id, onInput, value, isValid]);

	useEffect(() => {
		resetValue();
	}, [props.reset]);

	const resetValue = () => {
		dispatch({ type: 'RESET' });
	};

	const changeHandler = (event) => {
		dispatch({
			type: 'CHANGE',
			val: event.target.value,
			validators: props.validators,
		});
	};

	const touchHandler = () => {
		dispatch({
			type: 'TOUCH',
		});
	};
	let element;

	if (props.type === 'text' || props.type === 'password') {
		element = (
			<input
				id={id}
				type={props.type}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={value}
			/>
		);
	}

	if (props.type === 'textarea') {
		element = (
			<textarea
				id={id}
				rows={props.rows || 3}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={value}
			/>
		);
	}

	if (props.type === 'select') {
		element = (
			<select id={id} value={value} onChange={changeHandler}>
				{props.options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.name}
					</option>
				))}
			</select>
		);
	}

	return (
		<div
			className={`form-control ${
				!inputState.isValid && inputState.isTouched && 'form-control--invalid'
			} `}
			style={props.style}
		>
			<label htmlFor={props.id}>{props.label}</label>
			{element}
			{!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
		</div>
	);
};

export default Input;
