import React from "react";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

import Landing from "./landing/pages/landing";
import Footer from "./shared/Components/Footer/Footer";
import Auth from "./auth/pages/login";

initializeApp({
  apiKey: "AIzaSyCU60pcAojOcfQ6hVgo5VpFTMjTK_BEers",
  authDomain:
    "firebase-adminsdk-kgwby@ha-dashboard-82953.iam.gserviceaccount.com",
  projectId: "ha-dashboard-82953",
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#06f97f",
    },
  },
});

const auth = getAuth();
auth.useDeviceLanguage();

const App = () => {
  const { token, email, login, logout } = useAuth();

  let isLoggedIn = (
    <React.Fragment>
      <Landing />
      <Footer />
    </React.Fragment>
  );

  if (!token) {
    isLoggedIn = <Auth />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <AuthContext.Provider
        value={{
          token: token,
          email: email,
          login: login,
          logout: logout,
        }}
      >
        {isLoggedIn}
      </AuthContext.Provider>
    </MuiThemeProvider>
  );
};
export default App;
