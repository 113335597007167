import React from 'react';

import { useForm } from '../../shared/hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';

import Input from '../../shared/Components/FormElements/Input';
import Button from '../../shared/Components/FormElements/Button';

import '../pages/login.css';

const OTPForm = (props) => {
	const inputs = {
		otp: {
			value: '',
			isValid: false,
		},
	};
	const [formState, inputHandler] = useForm(inputs, false);

	const OTPVerification = (event) => {
		event.preventDefault();

		const otp = formState.inputs.otp.value;
		props.OTPHandler(otp);
	};

	return (
		<form onSubmit={OTPVerification}>
			<div className="auth__inputs">
				<div>
					<div className="auth__input-container">
						<Input
							id="otp"
							type="text"
							label="Código secreto"
							validators={[VALIDATOR_REQUIRE()]}
							placeholder=""
							initialValue=""
							reset
							errorText="Ingrese un código válido"
							onInput={inputHandler}
						/>
					</div>
				</div>
			</div>
			<div>
				<Button
					id="sign-in-button"
					disabled={!formState.isValid}
					type="submit"
					contained
				>
					Verificar código
				</Button>
			</div>
		</form>
	);
};

export default OTPForm;
