import React, { useEffect, useContext, useState } from 'react';

import {
	signInWithEmailAndPassword,
	getAuth,
	signOut,
	RecaptchaVerifier,
	signInWithPhoneNumber,
} from 'firebase/auth';
import {
	getFirestore,
	collection,
	query,
	where,
	getDocs,
	limit,
} from 'firebase/firestore';

import { AuthContext } from '../../shared/context/auth-context';

import EmailForm from '../components/email';
import OTPForm from '../components/otp';
import LoadingSpinner from '../../shared/Components/UIElements/LoadingSpinner';

import Logotipo from '../../assets/humanapis/logotipo.png';

import './login.css';

const Auth = () => {
	const firebaseAuth = getAuth();
	const auth = useContext(AuthContext);

	const [emailMode, setEmailMode] = useState(true);
	const [OTPMode, setOTPMode] = useState(false);
	const [user, setUser] = useState(false);

	const [isLoading, setIsloading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		const renderRecaptcha = async () => {
			window.recaptchaVerifier = new RecaptchaVerifier(
				'recaptcha-container',
				{
					size: 'normal',
					callback: (response) => {},
					'expired-callback': () => {
						setEmailMode(true);
						setOTPMode(false);
					},
				},
				firebaseAuth
			);
			window.recaptchaVerifier.render();
		};
		renderRecaptcha();
	}, [firebaseAuth]);

	const firebaseLogin = async (email, password) => {
		let userData;
		try {
			userData = await signInWithEmailAndPassword(
				firebaseAuth,
				email,
				password
			);
			setUser(userData.user);
		} catch (err) {
			if (err.message === 'Firebase: Error (auth/wrong-password).') {
				setError('Credenciales inválidas');
			} else {
				setError(err.message);
			}
			return false;
		}
		return userData.user;
	};

	const getUserPhone = async (email) => {
		let phone = false;
		try {
			const db = getFirestore();
			const usersRef = collection(db, 'iframes');
			const q = query(usersRef, where('usuario', '==', email), limit(1));
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				phone = doc.data().phone;
			});
		} catch (err) {
			signOut(firebaseAuth);
		}
		return phone;
	};

	const sendOtp = async (phone) => {
		try {
			const confirmationResult = await signInWithPhoneNumber(
				firebaseAuth,
				phone,
				window.recaptchaVerifier
			);
			window.confirmationResult = confirmationResult;
		} catch (err) {
			signOut(firebaseAuth);
			return false;
		}
		return true;
	};

	const loginHandler = async (email, password) => {
		setIsloading(true);
		if (error) {
			setError(false);
		}
		const userData = await firebaseLogin(email, password);

		if (!userData) {
			setIsloading(false);
			return;
		}

		const phone = await getUserPhone(email);

		if (!phone) {
			setIsloading(false);
			return;
		}

		const isSent = await sendOtp(phone);

		if (!isSent) {
			setIsloading(false);
			return;
		}

		setIsloading(false);
		setEmailMode(false);
		setOTPMode(true);
	};

	const OTPHandler = async (code) => {
		setIsloading(true);
		try {
			await window.confirmationResult.confirm(code);
			auth.login(user.accessToken, user.email);
			setIsloading(false);
		} catch (err) {
			setEmailMode(true);
			setOTPMode(false);
			setIsloading(false);
		}
	};
	return (
		<main className="auth">
			{isLoading && <LoadingSpinner />}
			<div className="auth__container">
				<div className="logo__container">
					<img src={Logotipo} alt="logotipo" />
				</div>
				{emailMode && <EmailForm error={error} emailAuthHandler={loginHandler} />}
				{OTPMode && <OTPForm OTPHandler={OTPHandler} />}
			</div>
		</main>
	);
};

export default Auth;
