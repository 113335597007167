import React from 'react';

import { useForm } from '../../shared/hooks/form-hook';
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_EMAIL,
	VALIDATOR_MINLENGTH,
} from '../../shared/utils/validators';

import Input from '../../shared/Components/FormElements/Input';
import Button from '../../shared/Components/FormElements/Button';

import '../pages/login.css';

const EmailForm = (props) => {
	const inputs = {
		email: {
			value: '',
			isValid: false,
		},
		password: {
			value: '',
			isValid: false,
		},
	};
	const [formState, inputHandler] = useForm(inputs, false);

	const authSubmitHandler = (event) => {
		event.preventDefault();

		const email = formState.inputs.email.value;
		const password = formState.inputs.password.value;

		props.emailAuthHandler(email, password);
	};

	return (
		<React.Fragment>
			<form onSubmit={authSubmitHandler}>
				<div className="auth__inputs">
					<div>
						<div className="auth__input-container">
							<Input
								id="email"
								type="text"
								label="Email"
								validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
								errorText="Ingrese un email válido"
								onInput={inputHandler}
							/>
						</div>
						<div className="auth__input-container">
							<Input
								id="password"
								type="password"
								label="Contraseña"
								validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
								errorText="Ingresar una contraseña válida"
								onInput={inputHandler}
							/>
						</div>
					</div>
					<div id="recaptcha-container" style={{ marginBottom: '20px' }} />
					{props.error && (
						<div className="auth--error">
							<p>{props.error}</p>
						</div>
					)}
				</div>
				<div>
					<Button
						id="sign-in-button"
						disabled={!formState.isValid}
						type="submit"
						contained
					>
						Iniciar sesión
					</Button>
				</div>
			</form>
		</React.Fragment>
	);
};

export default EmailForm;
