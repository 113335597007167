import React, { useState, useEffect, useContext } from 'react';

import { AuthContext } from '../../shared/context/auth-context';

import {
	getFirestore,
	collection,
	getDocs,
	where,
	limit,
	query,
} from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';

import './landing.css';

const Lading = () => {
	const firebaseAuth = getAuth();
	const auth = useContext(AuthContext);

	const [iframeUrl, setIframeUrl] = useState(false);

	useEffect(() => {
		if (!!auth.token) {
			const getIframe = async () => {
				const db = getFirestore();
				const usersRef = collection(db, 'iframes');
				const q = query(usersRef, where('usuario', '==', auth.email), limit(1));
				const responseData = await getDocs(q);
				responseData.forEach((doc) => {
					const docData = doc.data();
					if (docData.usuario === auth.email) {
						setIframeUrl(docData.iframe);
					} else {
						signOut(firebaseAuth);
						auth.logout();
					}
				});
			};
			getIframe();
		} else {
			signOut(firebaseAuth);
			auth.logout();
		}
	}, [auth, firebaseAuth]);

	return (
		<main>
			{iframeUrl && (
				<iframe
					className="powerbi__iframe"
					title="humantouch dashboard"
					src={iframeUrl}
					frameBorder="0"
					allowFullScreen
				></iframe>
			)}
		</main>
	);
};

export default Lading;
