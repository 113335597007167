import React from 'react';

import { CircularProgress } from "@material-ui/core";
import Backdrop from './Backdrop';

const LoadingSpinner = props => {
    return (
        <Backdrop onClick={null}>
            <CircularProgress color="secondary" />
        </Backdrop>
    )
}
export default LoadingSpinner;