import { useState, useEffect, useCallback } from 'react';

import { getAuth, signOut } from 'firebase/auth';

let logoutTimer;

export const useAuth = () => {
	const auth = getAuth();

	const [token, setToken] = useState(null);
	const [email, setEmail] = useState(null);
	const [tokenExpirationDate, setTokenExpirationDate] = useState();

	const login = useCallback((token, email, expirationDate) => {
		const tokenExpirationDate =
			expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
		setTokenExpirationDate(tokenExpirationDate);
		localStorage.setItem(
			'userData',
			JSON.stringify({
				token: token,
				email: email,
				expiration: tokenExpirationDate.toISOString(),
			})
		);
		setEmail(email);
		setToken(token);
	}, []);

	const logout = useCallback(async () => {
		setToken(null);
		setEmail(null);
		setTokenExpirationDate(null);
		localStorage.removeItem('userData');
		await signOut(auth);
	}, [auth]);

	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
			logoutTimer = setTimeout(logout, remainingTime);
		} else {
			clearTimeout(logoutTimer);
		}
		signOut(auth);
	}, [token, logout, tokenExpirationDate, auth]);

	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('userData'));
		if (
			storedData &&
			storedData.token &&
			storedData.email &&
			new Date(storedData.expiration) > new Date()
		) {
			login(storedData.token, storedData.email, new Date(storedData.expiration));
		}
		signOut(auth);
	}, [login, auth]);

	return { token, email, login, logout };
};
